import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';

// Styled Components
export const Intro = styled.div`
  ${minWidth.lg`
    grid-column: 3 / span 8;
  `}
`;

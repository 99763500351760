import React, { useRef } from 'react';

import get from 'lodash/get';

import {
  Hero,
  HeroDynamic,
  HeroCarousel,
  HeroBubble,
  WrapperIntro,
} from 'components/organisms';
import Intro from 'components/molecules/intro/Intro';
import Subhead from 'components/atoms/subhead/Subhead';
import { ModuleRenderer } from 'utils/module-renderer';
import { PageDataLoader } from 'utils/page-data-loader';
import { VerifyPageContext } from 'utils/verify-page-context';
import { IntroContext } from 'contexts/intro-context';
import { QUERY_TYPES } from 'constants/query';
import { FULL_BLEED_COMPONENTS } from 'constants/modules';
import { Portal } from 'components/atoms/portal';
import LocaleNudgeBanner from '../../components/molecules/locale-nudge-banner/LocaleNudgeBanner';
import { getFilteredComponents } from '../../utils/get-filtered-components';

/**
 * Renders the Home Page template.
 * @param {Object} pageData The Contentful data object.
 */
const HomePage = ({ pageData }) => {
  const scrollToRef = useRef(null);

  const {
    modulesCollection: { items: components },
    __typename: pageContext,
    hero,
    heroCarousel,
    heroDynamic,
    heroBubble,
    subhead,
    introText,
  } = pageData;

  const verifiedContext = VerifyPageContext(pageContext);
  const [{ __typename: moduleType }] = components;
  const isFullBleedComponent = FULL_BLEED_COMPONENTS.includes(moduleType);
  const filteredComponents = getFilteredComponents(components);

  return (
    <article>
      {/* Hero */}
      {heroCarousel && (
        <HeroCarousel data={pageData} pageContext={verifiedContext} />
      )}

      {/* Hero */}
      {heroBubble && !heroCarousel && (
        <HeroBubble data={pageData} scrollRef={scrollToRef} />
      )}

      {/* Hero */}
      {hero && !heroCarousel && !heroBubble && (
        <Hero
          key={get(hero, 'sys.id')}
          data={pageData}
          pageContext={verifiedContext}
        />
      )}

      {/* Hero */}
      {heroDynamic && !heroCarousel && !hero && !heroBubble && (
        <HeroDynamic data={pageData} pageContext={verifiedContext} />
      )}
      <div ref={scrollToRef}>
        {(introText || subhead) && (
          <IntroContext.Provider
            value={{
              withHeight: isFullBleedComponent,
              withIntro: !!introText,
            }}
          >
            <WrapperIntro>
              {/* Subhead */}
              {subhead && <Subhead>{subhead}</Subhead>}

              {/* Intro Text */}
              {introText && <Intro bodyText={introText} />}
            </WrapperIntro>
          </IntroContext.Provider>
        )}
        {/* Modules */}
        <section>
          <ModuleRenderer
            components={filteredComponents}
            pageContext={verifiedContext}
          />
        </section>
        <Portal>
          <LocaleNudgeBanner />
        </Portal>
      </div>
    </article>
  );
};

export default PageDataLoader(HomePage, {
  pageSlug: false,
  queryType: QUERY_TYPES.PAGE_HOME_QUERY,
});
